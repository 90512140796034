<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Forma di pagamento"
                v-model="filter.byAttribute.title"
                placeholder="Inserisci una forma di pagamento"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="display"
                type="text"
                label="Codice Forma di Pagamento"
                v-model="filter.byAttribute.display"
                placeholder="Inserisci un codice forma di pagamento"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <treasuries
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{ bySector: { is_agency: 'Y' } }"
      @destroy="onDestroy"
      :ref="tableRef"
    ></treasuries>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Treasuries from "@/components/tables/Treasuries.vue";
import BaseInput from "@/components/form/BaseInput";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "treasury",
      resource: "treasuries",
      tableRef: "AgencyTreasuryTableRef",
      filterName: "filterAgencyTreasury",
      filter: this.initFilter(),
      fields: [
        {
          key: "title",
          label: "Descrizione Forma di Pagamento",
          sortable: true,
          sortKey: "title",
        },
        {
          key: "display",
          label: "Codice Forma di Pagamento",
          sortable: true,
          sortKey: "display",
        },
        {
          key: "sector",
          label: "Comparto",
        },
      ],
    };
  },
  components: {
    Treasuries,
    BaseInput,
  },
  mounted() {},
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          display: null,
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit0`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create0` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$store.dispatch("auth/treasuries");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Cassa di agenzia eliminata con successo`,
          });
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },

    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },

    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
    }),
  },
};
</script>
